<template>
  <g :fill="color" fill-rule="evenodd">
    <circle cx="9" cy="9" r="9" />
    <path
      fill="#FFF"
      fill-rule="nonzero"
      d="M12.27 10.26a.7.7 0 0 0 .99-.99l-3.177-3.177a.7.7 0 0 0-.99 0L5.917 9.27a.7.7 0 0 0 .99.99l2.681-2.682 2.682 2.682z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
